import React, { useEffect, useState } from 'react';

const LowEligibleParticipantsWarning = ({
                                          isLoading,
                                          isMturk,
                                          eligibleParticipantsCountForMturk,
                                          eligibleParticipantsCountForCint,
                                          expiresAfter,
                                          numberOfSubmissions,
                                          maxTimePerSubmission,
                                          maxInterviewLength,
                                          className,
                                        }) => {
  const [showWarning, setShowWarning] = useState(false);

  const shouldShowWarning = () => {
    if (isMturk) {
      return eligibleParticipantsCountForMturk < numberOfSubmissions;
    }

    if (!maxTimePerSubmission || !expiresAfter || maxTimePerSubmission > maxInterviewLength) {
      return false;
    }

    return eligibleParticipantsCountForCint < numberOfSubmissions;
  };

  useEffect(() => {
    const hasValidParticipantCounts =
      typeof eligibleParticipantsCountForMturk === 'number' ||
      typeof eligibleParticipantsCountForCint === 'number';

    if (hasValidParticipantCounts) {
      const warningState = shouldShowWarning();
      setShowWarning((prev) => (prev && warningState) || warningState);
    }
  }, [
    isMturk,
    eligibleParticipantsCountForMturk,
    eligibleParticipantsCountForCint,
    expiresAfter,
    numberOfSubmissions,
    maxTimePerSubmission,
    maxInterviewLength,
  ]);

  return (
    showWarning && (
      <div className={`notice ml-0 mr-0 pb-2 highlight-box ${className || ''}`}>
        <span>
          Warning: Your desired number of activity completions exceeds the eligible participants for the
          study. To ensure your study can be completed, please adjust your number of participants or
          modify/remove filters.
        </span>
      </div>
    )
  );
};

export default LowEligibleParticipantsWarning;
