import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {isCintPlatform} from '../utils/constants'

import {
  createDemographicAnswerReset,
  createDemographicAnswerSubmit,
  updatePreviousAnswers,
  validateAnswerReset
} from '../redux/actions'

const $ = window.$

const PreviousAnswers = ({assignmentId, answers, platform, defaultCintQuestions, validateAnswer}) => {
  const [selectedAnswer, setSelectedAnswer] = useState({})
  const isCint = isCintPlatform(platform)
  const dispatch = useDispatch()
  const {
    validateAnswerState = {},
    createDemographicAnswer = {}
  } = useSelector(state => ({
    validateAnswerState: state.validateAnswerForPreviousAnswer,
    createDemographicAnswer: state.createDemographicAnswer
  }))
  const isValidating = validateAnswerState.isLoading
  const hasErrors = !!validateAnswerState.errors
  const createAnswerError = !!createDemographicAnswer.errors
  const isLoading = createDemographicAnswer.isLoading
  const nonEditableQuestions = Object.keys(defaultCintQuestions).map(k => defaultCintQuestions[k])

  const isEditable = (questionText) => !(isCint && nonEditableQuestions.includes(questionText))

  const getAnswerOption = (answer) => answer.answer_options.find(a => a.answer_text === answer.answer_text)

  const handleEditAnswer = (e, answer) => {
    e.preventDefault()

    let answerOption = {}
    if (answer.answer_type === 'select_box') {
      const selectedAnswerOption = getAnswerOption(answer)
      answerOption = {answer_option_id: selectedAnswerOption.id, answer_text: selectedAnswerOption.answer_text}
    }
    setSelectedAnswer({...answer, ...answerOption})

    $.magnificPopup.open({
      items: {src: '#edit-previous-answer'},
      type: 'inline',
      modal: true
    })
  }

  const closePopup = (e) => {
    if (e) e.preventDefault()
    $.magnificPopup.close()

    dispatch(validateAnswerReset())
    dispatch(createDemographicAnswerReset())
  }

  const handleUpdateAnswer = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const params = {
      assignment_id: assignmentId,
      question_id: selectedAnswer.question_id,
      question_type: selectedAnswer.question_type,
    }

    if (selectedAnswer.answer_type === 'text')
      params['answer_text'] = selectedAnswer.answer_text
    else
      params['answer_option_id'] = selectedAnswer.answer_option_id

    dispatch(validateAnswerReset())
    dispatch(createDemographicAnswerSubmit(params))
  }

  const handleSelectionChange = (e) => {
    const answerOptionId = e.target.value
    setSelectedAnswer(answer => {
      const selectedAnswerOption = answer.answer_options.find(a => a.id === answerOptionId)
      return {...answer, answer_option_id: answerOptionId, answer_text: selectedAnswerOption.answer_text}
    })
  }

  const handleYearOfBirthChange = (e) => {
    const yearOfBirth = e.target.value
    setSelectedAnswer(answer => ({...answer, answer_text: yearOfBirth}))
    validateAnswer(e, true, 'PreviousAnswer')
  }

  useEffect(() => {
    if (createDemographicAnswer.status === 'success') {
      closePopup()

      dispatch(updatePreviousAnswers(selectedAnswer))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDemographicAnswer.status])

  useEffect(() => {
    return () => {
      $.magnificPopup.close()
    }
  }, [])

  return (
    <div className="col-md-6 po-text po-ns">
      <div className="review-panel">
        <h3 className="m-0 font-size-18 mb-1">
          Your previously entered data
        </h3>
        <p className="mb-2 color-half-black">This helps us send you the right activities.</p>

        {answers && answers.map((answer, index) => (
          <div key={index} className="pt-2 answer-separator">
            <div>
              <p>{answer.question_text}</p>
              <h3 className="m-1 mb-2">
                {answer.answer_text}
              </h3>
            </div>
            {isEditable(answer.question_text) && (
              <a
                href="/"
                className="edit-icon"
                onClick={e => handleEditAnswer(e, answer)}
              >
                {''}
              </a>)}
          </div>
        ))}
      </div>

      <div id="edit-previous-answer" className="white-popup mfp-hide">
        <form
          noValidate
          name="updatePreviousAnswer"
          className="wrap_info p-4"
          onSubmit={handleUpdateAnswer}
        >
          <div className="p-2">
            {selectedAnswer.answer_type === 'text' && (
              <div className="wrap_item">
                <label className="text-required">{selectedAnswer.question_text}</label>
                <div className={`form-group ${(hasErrors || createAnswerError) ? 'has-error' : ''}`}>
                  <input
                    required={true}
                    name={selectedAnswer.question_id}
                    step="1"
                    type="number"
                    onChange={handleYearOfBirthChange}
                    value={selectedAnswer.answer_text}
                    data-hj-allow=''
                  />
                  {(hasErrors || createAnswerError) ? (
                    <p className="po-text-danger po-text">
                      Invalid {selectedAnswer.label}
                    </p>
                  ) : null}
                </div>
              </div>
            )}

            {selectedAnswer.answer_type === 'select_box' && (
              <div className="wrap_item">
                <label className="text-required">{selectedAnswer.question_text}</label>
                <div className="form-group">
                  <select
                    className="full-width"
                    id={selectedAnswer.question_id}
                    name={selectedAnswer.question_id}
                    onChange={handleSelectionChange}
                    required={true}
                    value={selectedAnswer.answer_option_id}
                  >
                    {selectedAnswer.answer_options.map((answerOption, index) => (
                      <option key={index} value={answerOption.id}>
                        {answerOption.answer_text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="wrap_link flex-row">
              <button
                className={`btn btn-primary clickable no-border ${isLoading || isValidating || hasErrors ? 'cursor-disabled btn-grey' : ''}`}
                disabled={isLoading || isValidating || hasErrors}
              >
                {isLoading ? 'Updating...' : 'Update'}
              </button>
              <button
                className="btn btn-default cansel clickable"
                onClick={closePopup}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PreviousAnswers
