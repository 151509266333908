import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import TimezoneSelect from 'react-timezone-select'
import swal from 'sweetalert'

// Custom components
import FormErrors from '../FormErrors'
import Spinner from '../common/Spinner'
import ChangePassword from '../ChangePassword'
import ResearchTypesInput from '../common/ResearchTypesInput'
import CountryPlatformSelect from '../common/CountryPlatformSelect'
import ActivityLanguageSelect from '../common/ActivityLanguageSelect'
import {getActivityLanguageById, getActivityLanguageByName} from '../run/enhanced-run-form/RunUtils'
import Input from '../common/Input'
import {isCintPlatform, isMturkPlatform} from '../../utils/constants'

// Redux actions
import {
  researchTypesFetch,
  countriesListFetch,
  resendConfirmationEmailFetch,
  activityLanguagesListFetch,
} from '../../redux/actions'
import {userAccountFetch, accountUpdateFetch} from '../../redux/actionss/userActions'
import {platformListFetch} from '../../redux/actionss/platformActions'

const $ = window.$

const Account = props => {
  const getDefaultLanguageID = () => {
    if (props.languages) {
      return getActivityLanguageByName(props.languages, 'English').id
    } else {
      return ''
    }
  }

  let initialValues = props.profile || {
    username: '',
    email: '',
    full_name: '',
    nick_name: '',
    time_zone: '',
    default_country: '',
    default_hourly_rate: '',
    default_run_duration: '',
    default_run_duration_type: '',
    default_platform_id: '',
    notify: false,
    show_pilot_warning: false,
    notify_run_completions: false,
    default_activity_language_id: getDefaultLanguageID()
  }

  const mturkMaxExpiryValues = {minutes: 525600, hours: 8760, weeks: 52, days: 365}
  const timeMultiplyValues = {minutes: 1, hours: 60, days: 1440, weeks: 10080}
  const [platformType, setPlatformType] = useState(props.platformType)
  const [availablePlatforms, setAvailablePlatforms] = useState([])
  const subscribtionStatus = useSelector(state => state.userAccount?.profile?.notify)

  if (props.profile && props.languages && !props.profile.default_activity_language_id) {
    initialValues = {
      ...initialValues,
      default_activity_language_id: getDefaultLanguageID()
    }
  }

  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Username is required')
        .min(6, 'Username must have at least 6 characters')
        .max(20, 'Username cannot have more than 20 characters')
        .matches(/^[a-zA-Z0-9]+$/, 'Username is invalid'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
      full_name: Yup.string()
        .matches(/^(([a-zA-Z]+)\s*)+$/, 'Full name is invalid'),
      nick_name: Yup.string()
        .matches(/^(([a-zA-Z0-9]+)\s*)+$/, 'Nick name is invalid'),
      default_hourly_rate: Yup.number()
        .required('Minimum hourly pay rate is $1.00')
        .min(1, 'Minimum hourly pay rate is $1.00'),
      default_run_duration: Yup.string()
        .required('Run duration is required'),
      research_types: Yup.array(),
      default_activity_language_id: Yup.string()
        .required('Activity language is required'),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      props.accountUpdateFetch(values)
    }
  })

  const isMturk = isMturkPlatform(platformType)

  const isCint = isCintPlatform(platformType)

  const handleFormikChange = (e, field = null) => {
    if (!field) {
      formik.handleChange(e)
    } else {
      formik.setFieldValue(field.name, field.value)
    }
  }

  const handleTimezoneChange = (option) => {
    const field = {name: 'time_zone', value: option?.value}
    handleFormikChange(null, field)
  }

  const resendInstructions = (e) => {
    e.preventDefault()
    props.resendConfirmationEmailFetch()
  }

  const getHourlyRateWarningMessage = () => {
    const hourlyRate = formik.values.default_hourly_rate
    let warning = {message: '', className: ''}

    if (hourlyRate >= 1 && hourlyRate <= 4) {
      warning = {
        message: 'Pay is very low',
        className: 'text-orange'
      }
    } else if (hourlyRate > 4 && hourlyRate < 7.25) {
      warning = {
        message: 'Pay is below $7.25/hr',
        className: 'text-yellow'
      }
    }
    return warning
  }

  const createActivityLanguageOption = (options) => {
    if (!options) return

    if (options.length && typeof options === 'object') {
      return options.map(option => ({
        label: option.label,
        value: option.id
      }))
    } else {
      const {id: value, label} = getActivityLanguageById(props.languages, options) || {}
      return {label, value}
    }
  }

  const handleActivityLanguageChange = (option) => {
    formik.setFieldValue('default_activity_language_id', option.value)
  }

  const handleChange = (e, value) => {
    formik.setFieldValue(e.target.name, value)
  }

  const onDefaultPlatformChange = (platform) => {
    setPlatformType(platform.platform_type)
  }

  const expiryTimeError = () => {
    const expiresAfter = parseFloat(formik.values.default_run_duration)
    const expiresAfterTimeType = formik.values.default_run_duration_type
    const expiryTimeInMinutes = timeMultiplyValues[expiresAfterTimeType] * expiresAfter

    if (isMturk) {
      if (expiryTimeInMinutes < 30) {
        return 'Can\'t be less than 30 minutes'
      }
      if (expiresAfter > mturkMaxExpiryValues[expiresAfterTimeType]) {
        return 'Can\'t be more than 365 days'
      }
    } else if (isCint) {
      if (expiresAfter < 1) {
        return 'Can\'t be less than a day'
      }

      if (expiresAfter > 31) {
        return 'Can\'t be more than 31 days'
      }
    }
  }

  useEffect(() => {
    props.researchTypesFetch()
    props.activityLanguagesListFetch()
    props.countriesListFetch()
    props.platformListFetch()
    props.userAccountFetch()

    return () => {
      $.magnificPopup?.close()
      if (swal.getState()?.isOpen) {
        swal.close()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(subscribtionStatus !== undefined) {
      const hash = window.location.hash.substring(1); // Get the hash part of the URL and remove the '#'
      const urlHash = hash.split('#')[1]; // Split the hash into pathHash and urlHash

      if (urlHash) {
        const targetElement = document.getElementById(urlHash); // Find the element with the hash in the URL
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth' // Use smooth scrolling if supported
          });
        }
      }
    }
  }, [subscribtionStatus]);

  const disableSubmit = () => (
    props.isLoading || props.isUpdating || !formik.isValid || expiryTimeError()
  )

  if (props.isLoading) {
    return <Spinner/>
  }

  return (
    <div>
      <div className="metrics project_detail">
        <div className="wrap_title d-flex">
          <h1>Account</h1>
        </div>
      </div>

      <div className="account">
        <form name="profileForm" onSubmit={formik.handleSubmit} noValidate>
          <FormErrors errors={props.errors}/>

          <div className="wrap_label">
            <label className="text-required">Username</label>
            <input
              name="username"
              id="username"
              type="text"
              className="text-required"
              onChange={formik.handleChange}
              value={formik.values.username}/>
            <span className="info">From 6 to 20 characters</span>
            {formik.dirty && formik.errors.username ? (
              <p className="po-text text-red-soft">{formik.errors.username}</p>
            ) : null}
          </div>

          <div className="wrap_label">
            <label className="text-required">Email</label>
            <input
              name="email"
              type="email"
              maxLength="100"
              onChange={formik.handleChange}
              value={formik.values.email}/>
            {formik.dirty && formik.errors.email ? (
              <p className="po-text text-red-soft">{formik.errors.email}</p>
            ) : null}

            {props.profile?.unconfirmed_email && (
              <div className="po-text my-2">
                <div className="notice-box">
                  We have sent confirmation instructions to {props.profile.unconfirmed_email}.&nbsp;
                  Please confirm your email to update in account.&nbsp;
                  <a className="po-link-color clickable" href="/" onClick={resendInstructions}>Resend instructions</a>
                </div>
              </div>
            )}
          </div>

          <ChangePassword/>

          <div className="wrap_label">
            <label>Full name</label>
            <input
              name="full_name"
              type="text"
              maxLength="50"
              onChange={formik.handleChange}
              value={formik.values.full_name}/>
            {formik.dirty && formik.errors.full_name ? (
              <p className="po-text text-red-soft">{formik.errors.full_name}</p>
            ) : null}
          </div>

          <div className="wrap_label">
            <label>Nick name</label>
            <input
              name="nick_name"
              type="text"
              maxLength="50"
              onChange={formik.handleChange}
              value={formik.values.nick_name}/>
            {formik.dirty && formik.errors.nick_name ? (
              <p className="po-text text-red-soft">{formik.errors.nick_name}</p>
            ) : null}
          </div>

          <div className="wrap_label time_zone_select">
            <label>Timezone</label>
            <TimezoneSelect
              value={formik.values.time_zone ? formik.values.time_zone : ''}
              onChange={handleTimezoneChange}
              classNamePrefix="react-select"
              styles={{
                container: styles => ({
                  ...styles,
                  width: '100%',
                  borderRadius: 4,
                  backgroundColor: '#fff',
                  appearance: 'none',
                  color: 'var(--input-text-color)',
                  fontSize: 14,
                  letterSpacing: -0.4,
                  fontFamily: 'Muli-SemiBold',
                }),
                valueContainer: (styles) => ({...styles, padding: '0 0 0 20px'}),
                control: (styles) => ({...styles, border: '1px solid #e2e9ef'}),
                indicatorSeparator: () => ({display: 'none'}),
                input: (styles) => ({...styles, padding: '0px', margin: '0px'}),
                singleValue: (styles) => ({
                  ...styles,
                  color: 'var(--input-text-color)',
                  width: 'calc(100% - 20px)',
                }),
                menuList: (styles) => ({...styles, maxHeight: 230}),
                option: (styles) => ({...styles, paddingLeft: 20}),
                noOptionsMessage: (styles) => ({...styles, paddingLeft: 20, textAlign: 'left'}),
                placeholder: (styles) => ({
                  ...styles,
                  color: 'var(--placeholder-color)',
                }),
              }}
            />
          </div>

          {props.selectedResearchTypes && (
            <ResearchTypesInput
              formik={formik}
              name="research_types"
              selectedResearchTypes={props.selectedResearchTypes}
            />
          )}

          <div className="wrap_title mt-3">
            <div className="title">Defaults</div>
            <div className="line"/>
          </div>

          <CountryPlatformSelect
            countryFieldName="default_country"
            countryFieldValue={formik.values.default_country}
            countryClassName="wrap_label"
            platformFieldName="default_platform_id"
            platformFieldValue={formik.values.default_platform_id}
            platformClassName="wrap_label"
            defaultPlatformId={formik.values.default_platform_id}
            onDefaultPlatformChange={onDefaultPlatformChange}
            formik={formik}
            availablePlatforms={availablePlatforms}
            setAvailablePlatforms={setAvailablePlatforms}
            isAccountsPage={true}
          />

          <ActivityLanguageSelect
            className="wrap_label"
            value={createActivityLanguageOption(formik.values.default_activity_language_id)}
            onChange={handleActivityLanguageChange}
            options={createActivityLanguageOption(props.languages)}
          />

          <div className="wrap_label">
            <label className="text-required">Hourly pay rate</label>
            <div className="wrap_select">
              <Input
                name="default_hourly_rate"
                type="number"
                value={formik.values.default_hourly_rate}
                onChange={handleChange}
                fractionDigits={2}
                step="0.01"
                min="1.00"
              />
              <select>
                <option value="$">$ US Dollar</option>
              </select>
            </div>
            {formik.dirty && formik.errors.default_hourly_rate && (
              <p className="po-text text-red-soft">{formik.errors.default_hourly_rate}</p>
            )}
            <p className={`po-text ${getHourlyRateWarningMessage().className}`}>
              {getHourlyRateWarningMessage().message}
            </p>
          </div>

          <div className="wrap_label">
            <label className="text-required">Run duration</label>
            <div className="wrap_select">
              <Input
                name="default_run_duration"
                type="text"
                value={formik.values.default_run_duration}
                onChange={handleChange}
                fractionDigits={2}
                allowOnlyNumbers
              />
              <select
                name="default_run_duration_type"
                value={formik.values.default_run_duration_type}
                onChange={formik.handleChange}
              >
                {isCint
                  ? (
                    <option value="days">Days</option>
                  )
                  : (
                    <>
                      <option value="minutes">Minutes</option>
                      <option value="hours">Hours</option>
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                    </>
                  )}
              </select>
            </div>
            {formik.dirty && formik.errors.default_run_duration ? (
              <p className="po-text text-red-soft">{formik.errors.default_run_duration}</p>
            ) : null}

            {expiryTimeError() && (
              <p className="po-text text-red-soft po-text mt-0" style={{lineHeight: '1.1rem'}}>
                {expiryTimeError()}
              </p>
            )}
          </div>

          <div className="wrap_label mb-2">
            <div className="radio-checkbox">
              <label>
                <input
                  className="checkbox"
                  id="show_pilot_warning"
                  type="checkbox"
                  checked={formik.values.show_pilot_warning}
                  onChange={formik.handleChange}/>
                <span className="checkbox-custom mr-2"/>
                <span>Show pilot warning for first run of every project</span>
              </label>
            </div>
          </div>

          <div className="wrap_title" id="notifications">
            <div className="title">Notifications</div>
            <div className="line"/>
          </div>

          <div className="wrap_label mb-2">
            <div className="radio-checkbox">
              <label>
                <input
                  className="checkbox"
                  id="notify_run_completions"
                  type="checkbox"
                  checked={formik.values.notify_run_completions}
                  onChange={formik.handleChange}/>
                <span className="checkbox-custom mr-2"/>
                <span>Notify me of run completions with feedback summary</span>
              </label>
            </div>
          </div>

          <div className="wrap_label">
            <div className="radio-checkbox">
              <label>
                <input
                  className="checkbox"
                  id="notify"
                  type="checkbox"
                  checked={formik.values.notify}
                  onChange={formik.handleChange}/>
                <span className="checkbox-custom mr-2"/>
                <span>Notify me of product updates and new features</span>
              </label>
            </div>
          </div>

          <div className="wrap_link">
            <button
              type="submit"
              id="users-profile-update"
              className={`btn btn-primary no-border clickable ${disableSubmit() ? 'btn-grey cursor-disabled' : ''}`}
              disabled={disableSubmit()}>
              {props.isUpdating ? 'Updating...' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.userAccount,
  isProfileUpdated: state.userAccount?.isUpdated,
  selectedResearchTypes: state.currentUser?.research_types,
  platforms: state.platformList?.availablePlatforms,
  languages: state.activityLanguages?.languages,
})

const mapDispatchToProps = dispatch => ({
  researchTypesFetch: () => dispatch(researchTypesFetch()),
  userAccountFetch: () => dispatch(userAccountFetch()),
  countriesListFetch: () => dispatch(countriesListFetch()),
  platformListFetch: () => dispatch(platformListFetch()),
  accountUpdateFetch: (values) => dispatch(accountUpdateFetch(values)),
  resendConfirmationEmailFetch: () => dispatch(resendConfirmationEmailFetch()),
  activityLanguagesListFetch: () => dispatch(activityLanguagesListFetch()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
