import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import qs from 'qs'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import Cookies from 'universal-cookie'

import Checkbox from '../common/Checkbox'
import SurveyProgressIndicator from './SurveyProgressIndicator'
import SurveySubscription from './SurveySubscription'
import SurveyEligibleParticipants from './SurveyEligibleParticipants'
import {errorToast} from '../helpers/notification'
import {getStorageItem, isCookieSupported, isLocalStorageSupported} from '../common/Helpers'
import FeedbackFallback from './FeedbackFallback'

import {
  markFeedbackStartedFetch,
  getPlatformTypeOfAssignmentFetch,
  submitFeedbackFetch,
  logCookieUsedAsFallback,
  markLinkVerifiedFetch
} from '../../redux/actions'

import p100 from '../../images/p100.svg'
import PositlyLink from '../common/PositlyLink'
import CopyLink from '../common/CopyLink'
import {isCintPlatform, isMturkPlatform, LINK_VERIFICATION_TYPES} from '../../utils/constants'
import Spinner from '../common/Spinner'

import './SurveyFeedbackForm.scss'

const SurveyFeedbackForm = (props) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [showReviewPanel, setShowReviewPanel] = useState(false)
  const [subscriptionCompleted, setSubscriptionCompleted] = useState(false)
  const [feedbackSkipped, setFeedbackSkipped] = useState(false)

  const {
    assignment,
    isLoading,
    errors,
    feedBackSubmitStatus,
    cintReturnUrl,
    isFeedbackLinkVerifying,
    feedbackLinkVerification,
    quotaFullUrl
  } = useSelector(
    (state) => ({
      assignment: state.postSurvey.feedbackStarted.assignment,
      isLoading: state.postSurvey.feedbackStarted.isLoading,
      errors: state.postSurvey.feedbackStarted.errors,
      feedBackSubmitStatus: state.postSurvey?.submitFeedback?.status,
      cintReturnUrl: state.postSurvey?.submitFeedback?.data?.cint_return_url,
      isFeedbackLinkVerifying: state.markLinkVerified?.isLoading,
      feedbackLinkVerification: state.markLinkVerified?.feedbackLinkVerification,
      quotaFullUrl: state.postSurvey?.feedbackStarted?.quotaFullUrl,
    })
  )

  const dispatch = useDispatch()
  const cookies = new Cookies()

  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const task_id = queryParams.task_id
  const activityLanguageName = assignment?.activity_language_name;

  const project_id = task_id
  let worker_id = getStorageItem('worker_id')
  let run_id = getStorageItem('run_id')
  let assignment_id = getStorageItem('assignment_id')
  let is_recruiter = getStorageItem('is_recruiter')
  let expectedTaskId = getStorageItem('lv_task_id')
  let completion_link_verification_id = getStorageItem(`lv_${expectedTaskId}_${LINK_VERIFICATION_TYPES.COMPLETION_LINK}_id`)

  if (assignment && !worker_id) {
    worker_id = assignment.worker_platform_uid
    run_id = assignment.run_uid
    assignment_id = assignment.platform_uid
  }

  const formik = useFormik({
    initialValues: {
      project_id: task_id,
      assignment_id: assignment_id,
      worker_id: worker_id,
      run_id: run_id,
      is_annoying: false,
      is_broken: false,
      is_confusing: false,
      is_unethical: false,
      is_unfairly_low_pay: false,
      is_unreasonably_hard: false,
      is_interesting: false,
      annoying_comment: '',
      broken_comment: '',
      confusing_comment: '',
      unethical_comment: '',
      unreasonably_hard_comment: '',
      interesting_comment: '',
      suggestion: '',
      pause_run: false,
    },
    validationSchema: Yup.object().shape({
      is_annoying: Yup.bool(),
      is_broken: Yup.bool(),
      is_confusing: Yup.bool(),
      is_unethical: Yup.bool(),
      is_unfairly_low_pay: Yup.bool(),
      is_unreasonably_hard: Yup.bool(),
      is_interesting: Yup.bool(),
      annoying_comment: Yup.string().when('is_annoying', {
        is: (value) => value,
        then: Yup.string().trim().required('Required').min(20),
      }),
      broken_comment: Yup.string().when('is_broken', {
        is: (value) => value,
        then: Yup.string().trim().required('Required').min(20),
      }),
      confusing_comment: Yup.string().when('is_confusing', {
        is: (value) => value,
        then: Yup.string().trim().required('Required').min(20),
      }),
      unethical_comment: Yup.string().when('is_unethical', {
        is: (value) => value,
        then: Yup.string().trim().required('Required').min(20),
      }),
      unreasonably_hard_comment: Yup.string().when('is_unreasonably_hard', {
        is: (value) => value,
        then: Yup.string().trim().required('Required').min(20),
      }),
      interesting_comment: Yup.string().trim(),
      suggestion: Yup.string().trim(),
      pause_run: Yup.bool(),
    }),
    onSubmit: (values) => {
      if (formik.isValid) {
        setFeedbackSubmitted(true)
        if (task_id) {
          if (!isAnyNegativeFeedback() && values.is_interesting && assignment?.assignment_hourly_rate > 12)
            setShowReviewPanel(true)

          let params = values
          if (values.pause_run && (values.is_interesting || !isAnyNegativeFeedback()))
            params = {...values, pause_run: false}

          if (!params.worker_id)
            params = {...params, run_id, worker_id, assignment_id}

          dispatch(submitFeedbackFetch(params))
        } else {
          errorToast('Your feedback has not been submitted.')
        }
      }
    },
  })

  const isAnyChecked = () => {
    return [
      formik.values.is_annoying,
      formik.values.is_broken,
      formik.values.is_confusing,
      formik.values.is_unethical,
      formik.values.is_unfairly_low_pay,
      formik.values.is_unreasonably_hard,
      formik.values.is_interesting,
    ].some((checked) => checked)
  }

  const isAnyNegativeFeedback = () => {
    return [
      formik.values.is_annoying,
      formik.values.is_broken,
      formik.values.is_confusing,
      formik.values.is_unethical,
      formik.values.is_unfairly_low_pay,
      formik.values.is_unreasonably_hard,
    ].some((checked) => checked)
  }

  const setupPageRefreshConfirmation = () => {
    if (assignment?.status !== 'feedback_completed' && formik && isAnyChecked()) {
      return 'You have not submitted the feedback. Reloading the page will result loss of feedback.'
    }
  }
  const isMturk = assignment && isMturkPlatform(assignment.platform_type)
  const isBonusActivity = isMturk && assignment && assignment.agree_screening_guidelines

  const isCint = assignment && isCintPlatform(assignment.platform_type)

  useEffect(() => {
    if (completion_link_verification_id && expectedTaskId === task_id) {
      dispatch(markLinkVerifiedFetch({id: completion_link_verification_id}))
    } else if (assignment_id) {
      // Mark feedback started
      const feedbackParams = {
        ...queryParams,
        task_id,
        worker_id,
        run_id,
        assignment_id,
      }
      dispatch(markFeedbackStartedFetch(feedbackParams))

      // Get platform type of assignment
      dispatch(getPlatformTypeOfAssignmentFetch(assignment_id))

      window.addEventListener('beforeunload', setupPageRefreshConfirmation)
    }

    return () => window.removeEventListener('beforeunload', setupPageRefreshConfirmation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (feedBackSubmitStatus === 'success') {
      if (assignment && isMturk) {
        // const params = {
        //   worker_id: assignment.worker_uid,
        //   user_id: assignment.user_id
        // }
        // TODO: Revert once getEligibleRuns API is optimized
        // dispatch(getEligibleHitsFetch(params))
      } else {
        window.location.href = cintReturnUrl
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedBackSubmitStatus])

  useEffect(() => {
    if (assignment && isMturk && ['feedback_completed', 'mturk_submitted'].includes(assignment.status)) {
      // const params = {
      //   worker_id: assignment.worker_uid,
      //   user_id: assignment.user_id
      // }
      // TODO: Revert once getEligibleRuns API is optimized
      // dispatch(getEligibleHitsFetch(params))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment?.status])

  useEffect(()=> {
    if(activityLanguageName !== undefined) {
      if (activityLanguageName !== 'English') {
        skipFeedbackForm()
      }
    }
  }, [activityLanguageName])

  const isFeedbackCompleted = () => (
    assignment && (assignment.feedback_completed_at || assignment.skip_feedback)
  )

  const isAssignmentCompleted = () => (
    assignment && !assignment.agree_screening_guidelines && isFeedbackCompleted() && (assignment.status === 'task_completed' || assignment.status === 'approved')
  )

  const showForcePauseOption = () => {
    if (isAnyChecked() && !formik.values.is_interesting) {
      return true
    }
  }

  const pauseRun = (e) => {
    e.preventDefault()
    formik.setFieldValue('pause_run', true)
  }

  const undoPauseRun = (e) => {
    e.preventDefault()
    formik.setFieldValue('pause_run', false)
  }

  const isValidPositiveComment = () => {
    const comment = formik.values.interesting_comment
    if (comment && comment.length > 0) {
      return (
        ['no', 'none', 'no thanks', 'nothing', 'na', 'n/a', 'nope'].indexOf(
          comment.toLowerCase()
        ) < 0
      )
    }

    return true
  }

  const disableSubmission = () =>
    !formik.isValid || feedbackSubmitted || !isValidPositiveComment()

  const isFeedbackSubmitable = () => (
    isAnyChecked() ||
    (formik.values.suggestion && formik.values.suggestion.length > 0)
  )

  const submitButtonText = () => {
    if (isFeedbackSubmitable()) {
      return 'Submit and Continue'
    } else {
      return 'Continue Without Feedback'
    }
  }

  const isSubscriptionCompleted = () => (
    typeof assignment?.worker_details?.notify == 'boolean' || subscriptionCompleted
  )

  const isValidAssignment = () => (
    assignment && ['abandoned', 'returned', 'rejected'].indexOf(assignment?.status) === -1
  )

  const isProjectCompleted = () => (
    assignment && !assignment.agree_screening_guidelines && (
      assignment.status === 'task_completed' || assignment.status === 'approved'
    )
  )

  const skipFeedbackForm = () => {
      setFeedbackSubmitted(true)
      setFeedbackSkipped(true)

      let params = formik.values
      params = {...params, run_id, worker_id, assignment_id, project_id}
      dispatch(submitFeedbackFetch(params))
  }

  const showFeedbackForm = () => {
   return !isFeedbackCompleted() && !errors && assignment && !isProjectCompleted() && !isLoading &&
          activityLanguageName && !feedbackSkipped
  }

  const progressBarUI = () => {
    return <div className="col-lg-12">
      {isValidAssignment() && (
        <ul className="status_bar">
          <li className="item step_1 passed">
            <div className="circle"/>
            <p>Accepted</p>
          </li>
          <li className="item step_2 passed">
            <div className="circle"/>
            <p>Pre-survey</p>
          </li>
          <li className="item step_3 passed">
            <div className="circle"/>
            <p>{isBonusActivity ? 'Bonus activity' : 'Main activity'}</p>
          </li>
          <li
            className={`item step_4 ${isFeedbackCompleted() || isProjectCompleted() ? 'passed' : 'active'}`}
            role="presentation"
          >
            <div className="circle"/>
            <p id="post-survey">
              Post survey
            </p>
          </li>
          <li
            className={`item step_5 ${(isFeedbackCompleted() || isProjectCompleted()) ? 'active' : ''}`}
            role="presentation"
          >
            <div className="circle"/>
            <p id="submitted-hit">
              Submitted
            </p>
          </li>
        </ul>
      )}
    </div>
  }

  useEffect(() => {
    if (!isLocalStorageSupported && isCookieSupported && worker_id)
      dispatch(logCookieUsedAsFallback({task_id, worker_id, run_id, assignment_id}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocalStorageSupported, isCookieSupported, worker_id])

  useEffect(() => {
    if (feedbackLinkVerification !== undefined) {
      if (isLocalStorageSupported) localStorage.removeItem(task_id)
      if (isCookieSupported) cookies.remove(task_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackLinkVerification])

  if (quotaFullUrl)
    window.location.href = quotaFullUrl

  if (isLoading) {
    return <Spinner></Spinner>
  }

  if (feedbackSkipped) {
    return <>
      {progressBarUI()}
      <div className="d-flex flex-column skip-feedback align-items-center text-center justify-content-center">
        <img src="/images/p100.svg"/>
        {isMturk && (
          <>
            <h3 className="mt-3">Thanks for participating!</h3>
            <p className="text-success">
              {assignment?.agree_screening_guidelines
                ? 'When your work is approved, you’ll be paid via bonus (this usually takes about 6 hours)'
                : 'You must now submit this HIT on Mechanical Turk so that you can be paid.'
              }
            </p>
          </>
        )}
      </div>
    </>
  }

  return (
    <>
      {completion_link_verification_id || expectedTaskId
        ? (isFeedbackLinkVerifying || quotaFullUrl ? (
            <div className="box">
              <div className="row po-text">
                <div className="col">
                  <h3>
                    {quotaFullUrl
                      ? 'Thanks for participating! Please wait while we redirect you to the Cint...'
                      : 'Please wait...'
                    }
                  </h3>
                </div>
              </div>
            </div>
          ) : (
            <div className="box">
              <div className="row po-text">
                <div className="col-md-8">
                  {task_id === expectedTaskId ? (
                    <label className="po-label d-flex">
                      <img src={p100} alt="" className="ml-2"/>{' '}
                      <span className="ml-3">
                        You reached your Completion link and your activity is working! If you modify the end of your activity, please run through this test again.
                      </span>
                    </label>
                  ) : (
                    <label className="po-label">
                      <p className="po-text-error error-o mb-2">Your Completion link setup is incorrect.</p>
                      <p className="po-p mb-2">Please change your Completion link at the end of your study to be:</p>
                      <div className="wrap_input_text form_style copy_completion_link_wrapper">
                        <PositlyLink
                          id="completion_link"
                          className="mw-none correct_link"
                          path={`/#/f?task_id=${expectedTaskId}`}
                        />
                        <CopyLink
                          label="Copy Completion Link"
                          idToCopy="completion_link"
                          className="btn btn-clipboard copy_link feedback mx-sm-1"
                        />
                      </div>
                      <p className="po-p mt-2">instead of:</p>
                      <p className="po-p mb-2">{window.location.href}</p>
                    </label>
                  )}
                </div>
              </div>
            </div>
          )
        ) : (
          <>
            {!isLoading && !assignment_id && (!errors || quotaFullUrl) && !worker_id && (!is_recruiter || !assignment) ? null : (
              <>
                <div className="row task-list-header align-items-center">
                  {/* Post survey top nav bar */}
                  {progressBarUI()}
                </div>
                {/* Survey form */}
                <div className="metrics project_detail filter participants activity">
                  {showFeedbackForm() ? (
                    <div className="post-survey-container">
                      <div className="wrap_title row align-items-center">
                        <h1>Post-survey</h1>
                      </div>

                      <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                          <div className="notice warn ml-0 p-10x mr-0">
                            <p className='pl-30x font-size-14'>
                              {isCint ? (
                                <span>
                            You will be redirected back to the Cint and get paid once you finish this feedback form.
                            Note that, if you don't want to give any feedback please click 'Continue Without Feedback'.
                          </span>
                              ) : (
                                <span>
                            If you don't want to give any feedback then leave all boxes unchecked and click 'Continue Without Feedback'.
                          </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-lg-7 col-md-12 left pr-0">
                          <div className="box">
                            <h2>
                              Was the activity you just completed any of the following?{' '}
                              <span className="po-text">(Optional)</span>
                            </h2>
                            <form
                              className="my-2 radio-checkbox"
                              name="feedbackForm"
                              noValidate="">
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_annoying"
                                  checked={formik.values.is_annoying}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Annoying</span>
                              </label>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_broken"
                                  checked={formik.values.is_broken}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Broken</span>
                              </label>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_confusing"
                                  checked={formik.values.is_confusing}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Confusing</span>
                              </label>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_unethical"
                                  checked={formik.values.is_unethical}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Unethical, harmful or prohibited</span>
                              </label>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_unfairly_low_pay"
                                  checked={formik.values.is_unfairly_low_pay}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Unfairly low pay</span>
                              </label>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_unreasonably_hard"
                                  checked={formik.values.is_unreasonably_hard}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Unreasonably hard</span>
                              </label>
                              <div className="hr-line"/>
                              <label className="my-2">
                                <Checkbox
                                  className="checkbox"
                                  name="is_interesting"
                                  checked={formik.values.is_interesting}
                                  onChange={formik.handleChange}
                                />
                                <span className="checkbox-custom"/>
                                <span className="po-text label">Interesting and enjoyable</span>
                              </label>
                              {formik.values.is_annoying && (
                                <div className="my-2">
                                  <h2>
                                    To submit this complaint, please explain why you
                                    feel the activity you just completed was "Annoying"
                                  </h2>
                                  <textarea
                                    name="annoying_comment"
                                    minLength={20}
                                    onChange={formik.handleChange}
                                    value={formik.values.annoying_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  <SurveyProgressIndicator
                                    value={formik.values.annoying_comment}
                                  />
                                </div>
                              )}
                              {formik.values.is_broken && (
                                <div className="my-2">
                                  <h2>
                                    To submit this complaint, please explain why you
                                    feel the activity you just completed was "Broken"
                                  </h2>
                                  <textarea
                                    minLength="20"
                                    name="broken_comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.broken_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  <SurveyProgressIndicator
                                    value={formik.values.broken_comment}
                                  />
                                </div>
                              )}
                              {formik.values.is_confusing && (
                                <div className="my-2">
                                  <h2>
                                    To submit this complaint, please explain why you
                                    feel the activity you just completed was "Confusing"
                                  </h2>
                                  <textarea
                                    minLength="20"
                                    name="confusing_comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.confusing_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  <SurveyProgressIndicator
                                    value={formik.values.confusing_comment}
                                  />
                                </div>
                              )}
                              {formik.values.is_unethical && (
                                <div className="my-2">
                                  <h2>
                                    To submit this complaint, please explain why you
                                    feel the activity you just completed was "Unethical,
                                    harmful or prohibited"
                                  </h2>
                                  <textarea
                                    minLength="20"
                                    name="unethical_comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.unethical_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  <SurveyProgressIndicator
                                    value={formik.values.unethical_comment}
                                  />
                                </div>
                              )}
                              {formik.values.is_unreasonably_hard && (
                                <div className="my-2">
                                  <h2>
                                    To submit this complaint, please explain why you
                                    feel the activity you just completed was
                                    "Unreasonably hard"
                                  </h2>
                                  <textarea
                                    minLength="20"
                                    name="unreasonably_hard_comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.unreasonably_hard_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  <SurveyProgressIndicator
                                    value={formik.values.unreasonably_hard_comment}
                                  />
                                </div>
                              )}
                              {formik.values.is_interesting && (
                                <div className="my-2">
                                  <h2>
                                    Any further comments about why it was "Interesting and enjoyable"?{' '}
                                    <span className="po-text">(Optional)</span>
                                  </h2>
                                  <textarea
                                    minLength="20"
                                    name="interesting_comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.interesting_comment}
                                    onPaste={(e) => e.preventDefault()}
                                    data-hj-allow=''
                                  />
                                  {!isValidPositiveComment() && (
                                    <p className="text-red po-text">
                                      Please leave this field blank if you have no
                                      specific feedback
                                    </p>
                                  )}
                                </div>
                              )}
                              <div className="my-2">
                                <h2>
                                  Do you have any specific suggestions for how the activity could be improved?{' '}
                                  <span className="po-text">(Optional)</span>
                                </h2>
                                <textarea
                                  minLength="20"
                                  name="suggestion"
                                  onChange={formik.handleChange}
                                  value={formik.values.suggestion}
                                  placeholder="Leave blank unless you have a specific suggestion"
                                  onPaste={(e) => e.preventDefault()}
                                  data-hj-allow=''
                                />
                                <SurveyProgressIndicator
                                  value={formik.values.suggestion}
                                />
                              </div>
                              {showForcePauseOption() && (
                                <div className="mt-4">
                                  <div id="comments_pause_run">
                                    {!formik.values.pause_run && (
                                      <h2 className="link-visited">
                                        If the problem you had with this run is serious
                                        enough that you think the run should be paused,{' '}
                                        <Link to="" onClick={pauseRun}>
                                          click here
                                        </Link>
                                      </h2>
                                    )}
                                    {formik.values.pause_run && (
                                      <h2 className="link-visited">
                                        Thank you for your feedback! We will consider pausing this run. {' '}
                                        <Link to="" onClick={undoPauseRun}>
                                          Click here
                                        </Link>
                                        {' '} to undo your request.
                                      </h2>
                                    )}
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="wrap_link wrap_link_filter d-flex">
                            <button
                              className={`btn btn-primary no-border clickable transition-0 ${
                                disableSubmission() && 'cursor-disabled btn-grey'
                              }`}
                              id="submitted-hit"
                              onClick={formik.handleSubmit}
                              disabled={disableSubmission()}
                              type="submit"
                            >
                              {submitButtonText()}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* Survey subscription form */}
                  {!isSubscriptionCompleted() ? (
                    <SurveySubscription
                      isFeedbackCompleted={isFeedbackCompleted}
                      isMturk={isMturk}
                      setSubscriptionCompleted={(value) => setSubscriptionCompleted(value)}
                    />
                  ) : null}
                </div>
              </>
            )}
            {/* Survey eligible participants */}
            {isFeedbackCompleted() && !isAssignmentCompleted() && (
              <SurveyEligibleParticipants
                subscriptionCompleted={isSubscriptionCompleted}
                isMturk={isMturk}
                showReviewPanel={showReviewPanel || assignment?.show_review_panel}
                isScreeningEnabled={assignment?.agree_screening_guidelines}
              />
            )}
            {/* Completed task warning */}
            {!isLoading && !assignment_id && !errors && !worker_id && !assignment ? (
              is_recruiter
                ? (
                  <div className="metrics project_detail filter participants activity mt-5">
                    <div className="box">
                      <h2>
                        This is the completion page for your activity. At the end of your activity you must direct
                        participants to this page so that they can confirm their completion and receive their payment.
                      </h2>
                    </div>
                  </div>
                ) : <FeedbackFallback taskId={task_id}/>
            ) : null}
            {/* If already completed the activity */}
            {isAssignmentCompleted() && (
              <div className="project_block">
                <div className="wrap_top wrap_projects">
                  <div className="title">
                    You have already completed this activity.
                  </div>
                </div>
              </div>
            )}
            {/* Post survey errors if any */}
            {errors && errors.length && !quotaFullUrl ? (
              <div className="project_block">
                <div className="wrap_top wrap_projects bg-white">
                  <div className="po-text-error font-size-18 title">{errors.join(', ')}</div>
                </div>
              </div>
            ) : null}
          </>
        )}
    </>
  )
}

export default SurveyFeedbackForm
