import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {cloneDeep, isEqual} from 'lodash'

// Custom components
import Checkbox from '../../common/Checkbox'

// Redux actions
import {runFormOverviewUpdate} from '../../../redux/actions'
import {DEFAULT_QUALITY_FILTER} from '../../../utils/constants'
import {toBoolean} from '../../common/Helpers'
import qualityGuard from '../../../images/qualityGuard.png'
import Input from '../../common/Input'
import {parseInt} from 'lodash/string'

const QualityFilter = (props) => {
  // Variables
  const dispatch = useDispatch()

  const taskParameters = useSelector(state => state.runFormOverview.task_parameters) || {}
  const runFormTaskParameters = useSelector(state => state.runForm.task_parameters) || {}

  const validationSchema = Yup.object().shape({
    task_parameters: Yup.object().shape({
      minimum_platform_approval_rate: Yup.number()
        .required('Platform activity approval rate is required')
        .min(0, 'Minimum platform activity approval rate should be 0')
        .max(100, 'Minimum platform activity approval rate can not exceed 100'),
      minimum_platform_tasks_completed: Yup.number()
        .required('Prior platform tasks completed is required')
        .min(0, 'Minimum prior platform tasks completed should be 0')
        .max(1000000, 'Minimum prior platform tasks completed can not exceed 1,000,000'),
      minimum_positly_pass_rate: Yup.number()
        .required(),
      minimum_positly_approvals: Yup.number()
        .required('Prior Positly approvals is required')
        .min(0, 'Minimum prior Positly approvals should be 0')
        .max(10000, 'Minimum prior Positly approvals can not exceed 10,000')
    })
  })

  const formik = useFormik({
    initialValues: {task_parameters: {}},
    validationSchema: validationSchema,
  })

  const initQualityFilter = () => {
    const taskParams = Object.keys(runFormTaskParameters).includes('is_one_worker_per_ip')
      ? runFormTaskParameters
      : taskParameters

    const selectedQualityFilter = {
      task_parameters: {
        is_one_worker_per_ip: toBoolean(taskParams.is_one_worker_per_ip, true),
        is_isp_check_enabled: toBoolean(taskParams.is_isp_check_enabled, true),
        is_attention_check_enabled: toBoolean(taskParams.is_attention_check_enabled, true),
        is_country_check_enabled: toBoolean(taskParams.is_country_check_enabled, true),
        is_very_hq_enabled: toBoolean(taskParams.is_very_hq_enabled, false),
        minimum_platform_approval_rate: parseInt(taskParams.minimum_platform_approval_rate || '99'),
        minimum_platform_tasks_completed: parseInt(taskParams.minimum_platform_tasks_completed || '500'),
        minimum_positly_pass_rate: parseInt(taskParams.minimum_positly_pass_rate || '1'),
        minimum_positly_approvals: parseInt(taskParams.minimum_positly_approvals || '0')
      }
    }

    selectedQualityFilter.task_parameters.is_hq_participants_only = (
      selectedQualityFilter.task_parameters.minimum_platform_approval_rate === 99 &&
      selectedQualityFilter.task_parameters.minimum_platform_tasks_completed === 500
    )

    const isDefaultFilter = isEqual({task_parameters: DEFAULT_QUALITY_FILTER}, selectedQualityFilter)
    if (isDefaultFilter) {
      return resetQualityFilter()
    }

    console.log("aaaaaa: ", selectedQualityFilter)
    props.setAutoBlockBots(false)
    formik.setValues(selectedQualityFilter)
  }

  const showQualityControlInputs = props.isMturk && !props.autoBlockBots
    && !formik.values.task_parameters?.is_very_hq_enabled

  const resetQualityFilter = () => {
    const values = {task_parameters: cloneDeep(DEFAULT_QUALITY_FILTER)}
    formik.setValues(values)
  }

  const handleChange = (e) => {
    let {name, value, checked, type} = e.target
    if ((type === 'number' || type === 'select-one') && value !== '') {
      value = parseInt(value)
    }
    const values = cloneDeep(formik.values)
    values.task_parameters[name] = type === 'checkbox' ? checked : value

    if (name === 'is_very_hq_enabled' && !checked) {
      // reset the value to the defaults
      values.task_parameters.minimum_platform_approval_rate = 99
      values.task_parameters.minimum_platform_tasks_completed = 500
      values.task_parameters.minimum_positly_pass_rate = 1 // minimum 70%
      values.task_parameters.minimum_positly_approvals = 0
    }

    values.task_parameters.is_hq_participants_only = (
      values.task_parameters.minimum_platform_approval_rate === 99 &&
      values.task_parameters.minimum_platform_tasks_completed === 500
    )

    if (values.task_parameters.is_very_hq_enabled) {
      values.task_parameters.is_hq_participants_only = false

      // clear the hq filters
      values.task_parameters.minimum_platform_approval_rate = 99
      values.task_parameters.minimum_platform_tasks_completed = 1000
      values.task_parameters.minimum_positly_pass_rate = 2 // minimum 99%
      values.task_parameters.minimum_positly_approvals = 5
    }

    formik.setValues(values)
  }

  const validateFormAndDispatchValues = () => {
    if (!Object.keys(formik.values.task_parameters)) return

    setTimeout(() => {
      formik.validateForm().then((errors) => {
        const isQualityFilterValid = errors && !Object.keys(errors).length
        const values = cloneDeep(formik.values)
        dispatch(runFormOverviewUpdate({...values, isQualityFilterValid}))
      })
    }, 0)
  }

  useEffect(() => {
    initQualityFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validateFormAndDispatchValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.task_parameters.is_one_worker_per_ip,
    formik.values.task_parameters.is_isp_check_enabled,
    formik.values.task_parameters.is_hq_participants_only,
    formik.values.task_parameters.is_attention_check_enabled,
    formik.values.task_parameters.is_country_check_enabled,
    formik.values.task_parameters.is_very_hq_enabled,
    formik.values.task_parameters.minimum_platform_approval_rate,
    formik.values.task_parameters.minimum_platform_tasks_completed,
    formik.values.task_parameters.minimum_positly_pass_rate,
    formik.values.task_parameters.minimum_positly_approvals,
  ])

  useEffect(() => {
    if (props.resetQualityFilter) {
      resetQualityFilter();
    }
  }, [props.resetQualityFilter])

  // UI template
  const qualityFilterCheckboxes = () => {
    return (
      <>
        {props.isMturk && (
          <div className="d-flex align-items-center quality-filter-item">
            <label className="switch flex-no-shrink">
              <Checkbox
                id="country_check"
                name="is_country_check_enabled"
                checked={formik.values.task_parameters.is_country_check_enabled}
                onChange={handleChange}
              />
              <span className="slider round"/>
            </label>
            <span className="po-text label ml-2 lh-125p">
                Restrict IP address geolocation by Country{' '}
              <div className="d-inline-block po-tooltip participants-attributes-tooltip">
                  <span className="po-tooltiptext po-tooltip-position">
                    Prevents participants from IP addresses geolocated outside the targeted country
                  </span>
                </div>
              </span>
          </div>
        )}

        <div className="d-flex align-items-center quality-filter-item">
          <label className="switch flex-no-shrink">
            <Checkbox
              id="attention_check"
              name="is_attention_check_enabled"
              checked={formik.values.task_parameters.is_attention_check_enabled}
              onChange={handleChange}
            />
            <span className="slider round"/>
          </label>
          <span className="po-text label ml-2 lh-125p">
            Require attentive participants{' '}
            <div className="d-inline-block po-tooltip participants-attributes-tooltip">
              <span className="po-tooltiptext po-tooltip-position">
                Restricts participants to those that consistently pass attention checks
              </span>
            </div>
          </span>
        </div>

        {props.isCint && (
          <div className="po-text label mt-3 cint-auto-features-info">
            On the Panel Participants platform, IP address geolocation restrictions will always be
            automatically applied.
          </div>
        )}
      </>
    )
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-md-12 left">
        <div className="box run-completion-box quality-filter">
          <div className="filter-options">
            <div className="d-flex align-items-center quality-filter-item">
              <label className="switch flex-no-shrink">
                <Checkbox
                  id="ip_check"
                  name="is_one_worker_per_ip"
                  checked={formik.values.task_parameters.is_one_worker_per_ip}
                  onChange={handleChange}
                />
                <span className="slider round"/>
              </label>
              <span className="po-text label ml-2 lh-125p">
                Block duplicate IP addresses
              </span>
            </div>
            <div className="d-flex align-items-center quality-filter-item">
              <label className="switch flex-no-shrink">
                <Checkbox
                  id="isp_check"
                  name="is_isp_check_enabled"
                  checked={formik.values.task_parameters.is_isp_check_enabled}
                  onChange={handleChange}
                />
                <span className="slider round"/>
              </label>
              <span className="po-text label ml-2 lh-125p">
                Block suspicious IP addresses{' '}
                <div className="d-inline-block po-tooltip participants-attributes-tooltip">
                  <span className="po-tooltiptext po-tooltip-position">
                    Prevents participants from ISPs that are suspected to enable fraudulent activity.
                  </span>
                </div>
              </span>
            </div>
            {showQualityControlInputs && qualityFilterCheckboxes()}
            {props.isMturk && (
              <div className="d-flex align-items-center quality-filter-item">
                <label className="switch flex-no-shrink">
                  <Checkbox
                    id="very_hq_check"
                    name="is_very_hq_enabled"
                    checked={formik.values.task_parameters.is_very_hq_enabled}
                    onChange={handleChange}
                  />
                  <span className="slider round"/>
                </label>
                <span className="po-text label ml-2 lh-125p">
                Restrict to only the very highest quality standards{' '}
                  <div className="d-inline-block po-tooltip participants-attributes-tooltip">
                  <span className="po-tooltiptext po-tooltip-position">
                    Restricts to a minimum 99% approval rate, 1000 HITs and 99% attention check pass rate, 5 Positly approvals
                  </span>
                </div>
              </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {!showQualityControlInputs && (
        <div className="col-lg-6 col-md-12 right  run-quality-filter-section">
          <div className="box run-completion-box quality-filter">
            <div className="filter-options">
              {qualityFilterCheckboxes()}
            </div>
          </div>
        </div>
      )}
      {props.isMturk && showQualityControlInputs && (
        <div className="col-lg-6 col-md-12 right run-quality-filter-section">
          <div className="box run-completion-box quality-filter">
            <div className="quality-guard-inputs row">
              <div className="col-lg-6 col-md-12 left">
                <div className="form-group">
                  <label>
                    <h2 className="text-required">Minimum platform activity approval rate</h2>
                  </label>
                  <div className="percent-input">
                    <Input
                      id="minimum_platform_approval_rate"
                      type="number"
                      name="minimum_platform_approval_rate"
                      value={
                        formik.values.task_parameters.minimum_platform_approval_rate === 0
                          ? '0'
                          : formik.values.task_parameters.minimum_platform_approval_rate
                      }
                      min={0}
                      max={100}
                      className="form-control mb-0"
                      onChange={handleChange}
                      step={1}
                      preventDecimals={true}
                    />
                    <div>%</div>
                  </div>
                </div>
                {formik.errors.task_parameters?.minimum_platform_approval_rate ? (
                  <div className="po-text text-red-soft lh-1">
                    {formik.errors.task_parameters.minimum_platform_approval_rate}
                  </div>
                ) : null}
                <div className="form-group pt-3">
                  <label>
                    <h2 className="text-required">Minimum prior platform tasks completed</h2>
                  </label>
                  <Input
                    id="minimum_platform_tasks_completed"
                    type="number"
                    name="minimum_platform_tasks_completed"
                    value={
                      formik.values.task_parameters.minimum_platform_tasks_completed === 0
                        ? '0'
                        : formik.values.task_parameters.minimum_platform_tasks_completed
                    }
                    min={0}
                    max={1000000}
                    className="form-control mb-0"
                    onChange={handleChange}
                    step={1}
                    preventDecimals={true}
                  />
                </div>
                {formik.errors.task_parameters?.minimum_platform_tasks_completed ? (
                  <div className="po-text text-red-soft lh-1">
                    {formik.errors.task_parameters.minimum_platform_tasks_completed}
                  </div>
                ) : null}
              </div>
              <div className="col-lg-6 col-md-12 right quality-quard-fields">
                <div className="form-group">
                  <label>
                    <h2 className="text-required">Minimum Positly attention check pass rate</h2>
                  </label>
                  <select
                    name="minimum_positly_pass_rate"
                    onChange={handleChange}
                    value={formik.values.task_parameters.minimum_positly_pass_rate}
                  >
                    <option value={0}>No restrictions</option>
                    <option value={1}>70%</option>
                    <option value={2}>99%</option>
                  </select>
                </div>
                <div className="form-group pt-3">
                  <label>
                    <h2 className="text-required visible-desktop">Minimum prior Positly <br/>approvals</h2>
                    <h2 className="text-required hidden-desktop">Minimum prior Positly approvals</h2>
                  </label>
                  <Input
                    id="minimum_positly_approvals"
                    type="number"
                    name="minimum_positly_approvals"
                    value={
                      formik.values.task_parameters.minimum_positly_approvals === 0
                        ? '0'
                        : formik.values.task_parameters.minimum_positly_approvals
                    }
                    className="form-control"
                    min={0}
                    max={10000}
                    onChange={handleChange}
                    preventDecimals={true}
                  />
                </div>
                {formik.errors.task_parameters?.minimum_positly_approvals ? (
                  <div className="po-text text-red-soft mt-1 lh-1">
                    {formik.errors.task_parameters.minimum_positly_approvals}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QualityFilter
