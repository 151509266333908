import React, {useRef, useState} from 'react'

import Spinner from '../images/spinner.gif'


const ImageCheck = (props) => {
  const imageCheckAnswerRef = useRef()
  const [showSpinner, setShowSpinner] = useState(true)

  const makeVisible = (e) => {
    e.target.style.visibility = 'visible'
    setShowSpinner(false)
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    props.updateAnswer({question_id: props.answer.question_id, answer_text: imageCheckAnswerRef.current.value, is_invalid: false, is_image_check_answer: true})
    props.setImageCheckVerified(true)
  }

  return (
    <div>
      <div className="box">
        <div>
          <img className={!showSpinner ? 'd-none' : ''} src={Spinner} alt="Spinner"/>
        </div>
        <img alt="Attention Check" className="d-hidden img-image-check" src={props.answer.image_url} onLoad={makeVisible}/>
        <div className="form-group">
          <label>
            <h2 className="text-required">{props.answer.question_text}</h2>
          </label>
          <input
            name={props.answer.question_id}
            ref={imageCheckAnswerRef}
            type="text"
            placeholder="Please answer in English"
            maxLength={255}
            data-hj-allow=''
          />
        </div>
      </div>
      <div className="wrap_link my-3">
        <button
          className="btn btn-primary no-border clickable"
          id="image-check-button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default ImageCheck
